<template>
  <div>
    <Modal :options="{width: '40vw', close: true}" @close="$emit('close')">
        <div class="title">Agregar Dirección</div>
        <div class="body">     
            <div class="row form-group">
                <label for="calle" class="col-form-label col-sm-4">Calle  </label>
                <div class="col-sm-8"><input v-model="direccion.calle" type="text" name="direccion" id="direccion" class="form-control"></div>
            </div>            
            <div class=" row form-group">
                <label for="direccion" class="col-form-label col-sm-4">Código Postal  </label>
                <div class="col-sm-8"><input v-model="direccion.cp" type="text" name="direccion" id="direccion" class="form-control"></div> 
            </div>
            <div class="row form-group">
                <label for="numero_exterior" class="col-form-label col-sm-4">Número exterior  </label>
                <div class="col-sm-8"><input v-model="direccion.no_exterior" type="text" name="direccion" id="direccion" class="form-control"></div>
            </div>
            <div class="row form-group">
                <label for="numero_interior" class="col-form-label col-sm-4">Número interior  </label>
                <div class="col-sm-8"><input v-model="direccion.no_interior" type="text" name="direccion" id="direccion" class="form-control"></div> 
            </div>
            <div class="row form-group">
                <label for="colonia" class="col-form-label col-sm-4">Colonia  </label>
                <div class="col-sm-8"><input v-model="direccion.colonia" type="text" name="direccion" id="direccion" class="form-control"></div>    
            </div>
            <div class="row form-group">
                <label for="delegacion" class="col-form-label col-sm-4">Delegación ó municipio  </label>
                <div class="col-sm-8"><input v-model="direccion.delegacion" type="text" name="direccion" id="direccion" class="form-control"></div>  
            </div>
            <div class="row form-group">
                <label for="estado" class="col-form-label col-sm-4">Estado </label>
                <div class="col-sm-8">
                    <select v-model="direccion.estado" name="direccion.estado" id="direccion.estado" class="form-control">
                        <option value="null">Selecciona un estado</option>
                        <option v-for="estado in estados" :key="estado.id" :value="estado.valor">{{ estado.nombre }}</option>
                    </select>
                </div>  
            </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button type="button" class="btn btn-primary mr-2" @click="crear_direccion"> Agregar </button>
              <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import apiClientes from '@/apps/clientes/api/clientes';

export default {
    components:{
        Modal
    },
    props:{
        ticket:{
            type:Object,
            required:true,
        },
        estados:{
            type:Array,
            email:true,
        },        
    },
    data(){
        return{
            direccion:{
                cp:null,
                calle:null,
                no_exterior:null,
                no_interior:null,
                colonia:null,
                delegacion:null,
                estado:null,
            }
        }
    },
    methods:{
        async crear_direccion(){
            try {
                await apiClientes.crear_direccion_tickets(this.ticket.cliente_id, this.direccion);
                this.$emit('close');
                
            } catch (e) {
                this.$log.info('err', e);
                this.$helper.showAxiosError(e, 'Error');
            }
        }
    }

}
</script>

<style>

</style>